import {Options, Vue} from "vue-property-decorator";
import {InvoiceMovementsRoutesEnum} from "@/modules/invoice_movements/router";
import {InvoiceMovementFilters} from "@/modules/invoice_movements/pages/invoices/InvoiceFilters";
import moment from "moment";
import {invoiceMovementsService} from "@services/invoice_movements.service";
import DataTable from "primevue/datatable";
import {TicketStatusEnum} from "@/model/enums/TicketStatusEnum";
import InvoiceMovementsFilters from "@/modules/invoice_movements/pages/invoices/InvoicesFilters.vue";
import {ShiftsRoutesEnum} from "@/modules/shifts/router";
import {TicketsRoutesEnum} from "@/modules/tickets/router";
import {UsersRoutesEnum} from "@/modules/users/router";
import {ticketsService} from "@services/tickets.service";
import { PaymentStatusEnum } from "@/model/enums/PaymentStatusEnum";

@Options({
    name: 'InvoicePage',

    components: {
        DataTable,
        InvoiceMovementsFilters
    }
})
export default class InvoicePage extends Vue {

    advFilters: InvoiceMovementFilters = new InvoiceMovementFilters();
    invalid_tickets: any[] = null
    valid_ticket: any[] = null
    zero_tickets: any[] = null
    filters: any = {};

    get backRoute() {
        return { name: InvoiceMovementsRoutesEnum.INVOICE_MOVEMENTS_LIST }
    }

    get shiftRoute() {
        return ShiftsRoutesEnum.SHIFTS_DETAIL;
    }

    get ticketRoute() {
        return TicketsRoutesEnum.TICKETS_DETAIL;
    }

    get userRoute() {
        return UsersRoutesEnum.USER_DETAIL;
    }

    onFiltersChange() {
        // Filtro per Client

        this.filters.client_id = this.advFilters?.client?.id;
        if (this.advFilters.from && this.advFilters.to) {
            this.filters.from_period_at = moment(this.advFilters.from).format("YYYY-MM-DD")
            this.filters.to_period_at = moment(this.advFilters.to).format("YYYY-MM-DD")
        }
    }

    onDelete(ticket){
        this.$waitFor(
            async () => {
                await ticketsService.deleteStandard(ticket);
                this.loadData();
                this.$successMessage('Deleting successfully completed');
            },
            'Deleting failed'
        )
    }

    canDelete(ticket){
        return this.invalid_tickets?.length && ticket.status === TicketStatusEnum.ACCEPTED_BY_USER
    }

    get canShowCalculateButton(){
        return this.valid_ticket?.length
    }

    get canCalculate(){
        return (this.filters.client_id && this.filters.from_period_at && this.filters.to_period_at)
    }

    loadData(){
        this.$waitFor(
            async () => {
                const response:any = await invoiceMovementsService.calculate(this.filters);
                if(response.zero_tickets?.length){
                    this.zero_tickets = response.zero_tickets
                } else {
                    this.zero_tickets = []
                }
                if(response.invalid_tickets?.length){
                    this.invalid_tickets = response.invalid_tickets
                    this.valid_ticket = []
                } else if(response.valid_tickets_count){
                    this.valid_ticket = [response]
                    this.invalid_tickets = []
                } else {
                    this.valid_ticket = []
                    this.invalid_tickets = []
                }
            }
        )
    }

    createMovement(){
        this.$waitFor(
            async () => {
                await invoiceMovementsService.create(this.filters);
                this.$successMessage(this.$t(`invoice.messages.create_success`))
                this.loadData()
            }
        )
    }

    get TicketStatusEnum() {
        return TicketStatusEnum;
    }

    mounted() {
        this.onFiltersChange()
        this.calculateResults()
    }

    calculateResults(){
        if(this.canCalculate){
            this.loadData()
        }
    }

    getPaymentStatus(data){
         return data.payment_status ? this.$t(`ticket.payment_statuses.${PaymentStatusEnum[data.payment_status]}`) : '--'
    }

}
