import { Options, Vue } from "vue-class-component";
import {InvoiceMovementsRoutesEnum} from "@/modules/invoice_movements/router";
import {FilterMatchMode} from "primevue/api";
import {invoiceMovementsService} from "@services/invoice_movements.service";
import {AppTable, DeleteInvoiceDialog} from "@components";
import {SessionStorageEnum} from "@/utils/SessionStorageEnum";
import Table from "@components/AppTable/Table";
import moment from "moment";
import InvoiceMovementsFilters from "@/modules/invoice_movements/pages/invoices/InvoicesFilters.vue";
import {InvoiceMovementFilters} from "@/modules/invoice_movements/pages/invoices/InvoiceFilters";
import {fileDownload, getFileNameFromHeader} from "@/utils/utils";
import {InvoiceMovement} from "@/model/api/Invoice";

@Options({
    components: {
        AppTable,
        InvoiceMovementsFilters,
        DeleteInvoiceDialog
    },

    beforeRouteEnter(to, from, next) {
        if (from.name && !(from.name as string).startsWith(InvoiceMovementsRoutesEnum.PREFIX)) {
            sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_INVOICE_MOVEMENTS);
            sessionStorage.removeItem(SessionStorageEnum.INVOICE_MOVEMENTS_ADVANCED_FILTERS);
        }

        next();
    },

    beforeRouteLeave() {
        if (this.service?.cancelPendingRequests) {
            this.service.cancelPendingRequests();
        }
    },
})
export default class InvoicesPage extends Vue {

    filters: any = null;
    advFilters: InvoiceMovementFilters = new InvoiceMovementFilters();
    displayDeletingDialog: number = null;

    get service() {
        return invoiceMovementsService;
    }

    get stateKey() {
        return SessionStorageEnum.TABLE_STATE_INVOICE_MOVEMENTS
    }

    get newRoute() {
        return {
            name: InvoiceMovementsRoutesEnum.INVOICE_MOVEMENT_DETAIL,
            params: { invoiceId: 'new' }
        }
    }

    get SessionStorageEnum(){
        return SessionStorageEnum
    }

    onDeleted(){
        this.displayDeletingDialog = null
        this.refreshTable();
        this.$successMessage("Movement successfully deleted")
    }

    deleteMovement(id: number){

        this.displayDeletingDialog = id

        // this.$confirmMessage("Are you sure to delete this Movement?")
        //     .then( r => {
        //         if (r) {
        //             this.$waitFor(
        //                 async () => {
        //                     await invoiceMovementsService.deleteBySystem(({id} as InvoiceMovement));
        //                     this.refreshTable();
        //                     this.$successMessage("Movement successfully deleted")
        //                 },
        //                 "Deleting Movement failed"
        //             )
        //         }
        //     })
    }

    async downloadInvoices(id: number, invoice_type?){
        this.$waitFor(
            async () => {
                const response = await invoiceMovementsService.downloadInvoices(id, invoice_type)
                try {
                    const fileName = getFileNameFromHeader(response.headers)
                    fileDownload(
                        fileName,
                        response.data,
                        response.headers['content-type']
                    )
                } catch (err) {
                    this.$errorMessage(this.$t('Operation failed'))
                }
            }
        );

    }

    private initFilter() {
        this.filters = {
            created_at: {
                value: this.advFilters.from,
                matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            },
            client_id: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            }
        };
    }

    onReset() {
        this.advFilters = new InvoiceMovementFilters();
    }

    onFiltersChange() {
        const table = (this.$refs.dataTable as Table);

        if (table?.filters) {
            const filters = table.filters;

            if (this.advFilters.from && this.advFilters.to) {
                const from = moment(this.advFilters.from);
                const to = moment(this.advFilters.to);

                filters.created_at.value = [from, to];
                filters.created_at.matchMode = FilterMatchMode.BETWEEN;
            } else {
                filters.created_at.value = moment(this.advFilters.from);
                filters.created_at.matchMode = FilterMatchMode.GREATER_THAN_OR_EQUAL_TO;
            }


            // Filtro per Client
            if (filters.client_id) filters.client_id.value = this.advFilters?.client?.id;
        }

        table.applyFilter();
    }

    private refreshTable() {
        const table = (this.$refs.dataTable as Table);
        table.applyFilter();
    }

    created() {
        this.initFilter();
    }

}
