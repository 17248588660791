
  import { plainToClass } from "class-transformer";
  import moment from "moment";
  import { Options, Vue } from "vue-class-component";
  import {Model, Prop} from "vue-property-decorator";
  import {InvoiceMovementFilters} from "./InvoiceFilters";
  import {CompanyAutocomplete} from "@components";

  @Options({
    components: {
      CompanyAutocomplete
    }
  })
  export default class InvoiceMovementsFilters extends Vue {
    @Model("filters")
    advFilters: InvoiceMovementFilters;
    @Prop() storage: any;

    openCalendar(event) {
      (this.$refs as any).op?.toggle(event);
    }

    hideCalendar() {
      (this.$refs as any).op?.hide();
    }

    get fromDate() {
      const from = this.advFilters?.from_to?.[0];

      return from ? moment(from).format("YYYY/MM/DD") : "----/--/--";
    }

    get toDate() {
      const to = this.advFilters?.from_to?.[1];

      return to ? moment(to).format("YYYY/MM/DD") : "----/--/--";
    }

    onClientSelected() {
      this.emitFilters();
    }

    emitFilters() {
      this.saveAdvancedFilters();
      this.$emit("change", this.advFilters);
    }

    private saveAdvancedFilters() {
      if(this.storage){
        sessionStorage.setItem(
            this.storage,
            JSON.stringify(this.advFilters)
        );
      }

    }

    private restoreAdvancedFilters() {
      if(this.storage){
        const fromStorage = JSON.parse(
            sessionStorage.getItem(this.storage)
        );

        this.advFilters = fromStorage
            ? plainToClass(InvoiceMovementFilters, fromStorage)
            : this.advFilters;
      }

    }

    created() {
      this.restoreAdvancedFilters();
    }
  }
